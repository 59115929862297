import React from "react";
import Heading from "../Heading/Heading";
import SubHeading from "../Heading/SubHeading";
import FacultyList from "./FacultyList";
import FeedbackForm from "./FeedbackForm";

export default function Contact() {
  return (
    <div className="contact-container" id="contact">
      <div className="contact-main-container">
        <Heading title={"Contact"} />
        <div className="contact-main">
          <FacultyList />
          <div className="contact-details">
            <div className="contact-details-main">
              <SubHeading title={"address"} />
              <div>
                <p>
                  NSS-OFFICE,
                  <br /> Bannari Amman Institute of Technology,
                  <br /> Alathukombai Post,
                  <br /> Erode District,
                  <br /> Sathyamangalam,
                  <br /> Tamil Nadu- 638 401. ​
                </p>
                <p>
                  nss@bitsathy.ac.in <br />
                  Ph: +919488021151
                </p>
              </div>

              <FeedbackForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
