import axios from "axios";
import React, { useState, useEffect } from "react";
import { ReactYouTubeLite } from 'react-youtube-lite';

const Camp = () => {
  const [camps, setCamps] = useState([]);
  useEffect(() => {
    const Response = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/camps/");
      setCamps(data);
    };
    Response();
  }, []);
  // camps.reverse();
  console.log(camps);
  const display = camps.map((x, index) => {
    let ar = x.video.split("watch?v=");
    let a = ar[1];
    console.log(ar[1]);
    return (
      <div className="card vii" key={index}>
        <div className="c c2">
          <div className="height">
          <h3 >{x.date}</h3>
          {/* <div> */}
          <p >{x.description}</p>
          </div>

            <div className="dis">
             
              <div className="you-tube-video iframe" >
                          <ReactYouTubeLite  url={x.video} />
                          </div>

            </div>
            {/* </div> */}
          </div>
        </div>
      // </div>
      
    );
  });
  return (
    <div className="container">
      <div className="card3 ">{display}</div>
    </div>
  );
};
export default Camp;
