import React, { useState, useEffect } from "react";
import axios from "axios";
import SubHeading from "../Heading/SubHeading";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const Event = (props) => {
  const [even, setEven] = useState([]);
  const [x, setX] = useState(0);
  const [autoSilde, setAutoSlide] = useState(true);
  useEffect(() => {
    setX(0);
    const res = async () => {
      const { data } = await axios.get(
        "https://nss.bitsathy.ac.in/api/upcomingevent/"
      );
      // console.log(data);
      setEven(data);
    };
    res();
    // console.log(border1)
  }, []);
  useEffect(() => console.log("event", even), [even]);
  // useEffect(() => {

  //   // return ( setTimeout(handlerRight, 2000))
  // }, [x, even]);
  // console.log(x)
  const handlerRight = () => {
    if (x < even.length - 1) {
      setX(x + 1);
    }
    if (x >= even.length - 1) {
      setX(0);
    }
  };
  const handlerLeft = () => {
    if (x <= 0) {
      setX(even.length - 1);
    }
    if (x <= even.length - 1 && x > 0) {
      setX(x - 1);
    }
    // if(x<0){
    //     setX(even.length-2)
    // }
  };

  useEffect(() => {
    let timer = setTimeout(handlerRight, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [x, even]);

  return (
    <>
      {even.length ? (
        <div className="nith" style={{}}>
          {/* <button onClick={setX(x+1)}>n k</button> */}
          <div>
            <div className="event">
              <SubHeading title="upcoming events" />
              <div className="slid">
                <p
                  onClick={() => {
                    setAutoSlide(false);

                    handlerLeft();
                  }}
                  className="floating-btn btn-left"
                >
                  <MdArrowBackIos />
                </p>
                <p
                  onClick={() => {
                    setAutoSlide(false);
                    handlerRight();
                  }}
                  className="floating-btn btn-right"
                >
                  <MdArrowForwardIos />
                </p>
                {even.length > 0 ? (
                  <div className="event-info">
                    <p className="event-name">{even[x].event}</p>
                    <p className="event-date">{even[x].date}</p>
                  </div>
                ) : (
                  "null"
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="loading-error">
          Something went wrong! cannot load images{" "}
        </p>
      )}
    </>
  );
};

export default Event;
