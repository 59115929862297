import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
const HalfSlider = ({ url }) => {
  const [image, setImage] = useState([]);
  const [x, setX] = useState(0);
  useEffect(() => {
    setX(0);
    const res = async () => {
      const { data } = await axios.get(`https://nss.bitsathy.ac.in/api/${url}/`);
      console.log(data);
      setImage(data);
    };
    res();
  }, []);
  // console.log(image);

  useEffect(() => {
    let timer = setTimeout(sliderfunction, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [x, image]);
  // console.log(x)
  const sliderfunction = () => {
    if (x < image.length - 1) {
      setX(x + 1);
    }
    if (x >= image.length - 1) {
      setX(0);
    }
  };
  const handlerRight = () => {
    if (x < image.length - 1) {
      setX(x + 1);
    }
    if (x >= image.length - 1) {
      setX(0);
    }
  };
  const handlerLeft = () => {
    // console.log(x);

    if (x <= 0) {
      setX(image.length - 1);
    }
    if (x <= image.length - 1 && x > 0) {
      setX(x - 1);
    }
    // if(x<0){
    //     setX(image.length-2)
    // }
  };

  // console.log(x);

  return (
    <div className="nithiesh ">
      {image.length ? (
        <div style={{ position: "relative" }}>
          {/* <button onClick={setX(x+1)}>n k</button> */}
          <div>
            <div className="imagesss">
              {" "}
              {image.length > 0 ? (
                <img
                  src={image[x].image}
                  // style={{ width: "80%", height: "550px" }}
                  className="slider-img"
                  alt="bbj"
                />
              ) : null}
            </div>
            {image.length > 0 ? (
              <p className="image-description sub-slide">{image[x].event}</p>
            ) : null}
            <p onClick={handlerLeft} className="floating-btn btn-left">
              <MdArrowBackIos />
            </p>
            <p onClick={handlerRight} className="floating-btn btn-right">
              <MdArrowForwardIos />
            </p>
          </div>
        </div>
      ) : (
        <p className="loading-error">
          Something went wrong! cannot load images{" "}
        </p>
      )}
    </div>
  );
};

export default HalfSlider;
