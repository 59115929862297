import React, { useState, useEffect } from "react";
import axios from "axios";

const Volunteer = () => {
  const [talent, setTalent] = useState([]);
  useEffect(() => {
    const res = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/vt/");
      console.log(data);
      setTalent(data);
    };
    res();
  }, []);
  console.log(talent);

  const disp = talent.map((x, index) => {
    console.log(x.file);

    const arr = x.file.split(".");
    arr.reverse();

    console.log(arr);
    return (
      <div className="card" key={index}>
        <div className="c card-main">
          <div className="talent">
            {x.image1 == null ? null : <img src={x.image1} alt="bufvn" />}
            {x.image2 == null ? null : <img src={x.image2} alt="vbfj" />}
          </div>

          <div className="vid" style={{ display: "flex" }}>
            {arr[0] === "mp4" ? (
              <video  controls controlsList="nodownload">
                <source src={x.file} />
              </video>
            ) : (
              <img src={x.file} alt="images" />
            )}
          </div>
          <div className="niii box-container">
            <p className="box-title">{x.name}</p>
            <h2 className="box-content">{x.skill}</h2>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="container">
      <div className="card1">{disp}</div>
    </div>
  );
};

export default Volunteer;
