import axios from 'axios';
import React, {useState,useEffect} from 'react';

const Struc=()=>{
    const [Struct,setStruct]=useState([]);
    useEffect(()=>{
        const Response = async()=>{
          const {data}=await axios.get("https://nss.bitsathy.ac.in/api/structure/");
          setStruct(data);
        };
        Response();
    },[])
    // Struct.reverse();
    console.log(Struct);
    const show= Struct.map((x,index)=>{
        return(
            <div className='Struct_img' key={index}>
                {/* <div className="struct_img_display"> */}
                <img src={x.image} alt='struct'></img>
                {/* </div> */}
            </div>
        )
    })

    return(
        <div className='container'>
            <div className="card-img">
            {show}</div>
            </div>
    )
};



export default Struc;