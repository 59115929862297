import React,{useState,useEffect} from "react";
import bitlogo from '../../image/bit-logo.jpg';
import logo from '../../image/logo.png'
import corona from '../../image/corona.jpg';
import axios from 'axios';



export default function HomeHeader({datas}) {
 


  let varr = new Date().getFullYear();
  console.log(varr);
  let section_display = "flex";
  let header_height = "50";
  if (varr >= parseInt(datas[0].year)) {
    section_display = "none";
    header_height = "40vh";
  }
  return (
    <div
      className="home-header-container"
      style={{ minHeight: `${header_height}` }}
    >
      <div className="home-header">
        <div className="home-header-top">
          <a href="http://bitsathy.ac.in/" target="_blank">
            <div className="img-container">
              <img
                src={bitlogo }
                alt="bit logo"
              />
            </div>
          </a>
          <span className="company-name">
            BANNARI AMMAN INSTITUTE OF TECHNOLOGY
          </span>
          <div className="img-container">
            <img src={logo} alt="" />
          </div>
        </div>

        <div className="home-header-main">
          <div className="section-1">
            <span className="title ">National Service Scheme</span>
            <span className="tagline highlight">Not me but you</span>
          </div>
          <marquee width="auto" direction="left" height="40%">
           ⚛<b> NSS Day Celeberation</b> &nbsp; ⚛<b> Blood Donation Camp</b>{" "}
            &nbsp;⚛<b> 7 Day Special Camp </b> &nbsp; 
            ⚛<b> NHRC Seminar</b>{" "}
            &nbsp; ⚛<b> Technical Symposium </b> &nbsp; 
             ⚛<b> Sapling Plantation</b> &nbsp; ⚛
            <b> Swachh Bharath Mission</b> &nbsp; ⚛
            <b> Digital Literacy Mission</b> &nbsp; ⚛
            <b> Unnat Bharat Abhiyan Activities</b>
            &nbsp; ⚛<b> Awareness Rally</b>
          </marquee>

          <div className="section-2" style={{ display: `${section_display}` }}>
            <div>
              <img
                src={corona}
                alt="corona"
              />
              <p>Protect yourself and others from the spread of COVID-19</p>
              <img
                src={
corona }
                alt="corona"
              />
            </div>
            <div>
              <span>STAY HOME</span>
              <span>STAY SAFE</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
