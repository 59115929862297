import React from "react";
import Heading from "./Heading/Heading";
import SubHeading from "./Heading/SubHeading";

export default function MainSection(props) {
  return (
    <div className="main-section-container">
      <div className="main-section">
        <Heading title={props.heading} />
        {props.contents.map((content,index) => (
          <div key={index}>
            {content.title && <SubHeading title={content.title} />}
            {content.type === "para" ? (
              <>
                {content.img && <img
                 className={content.class}
                  src={content.img} alt={content.title} />}
                {content.content && (
                  <p className="content">{content.content}</p>
                )}
              </>
            ) : (
              <>
                {content.before && <p>{content.before}</p>}
                {content.content && (
                  <ul>
                    {content.content.map((item,index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
