import React,{useState,useEffect} from "react";
import axios from 'axios';
import Event from "./Slider/event";
import HomeHeader from "./HomeHeader";
import MainSection from "./MainSection";
import FullSlider from "./Slider/FullSlider";
import HalfSlider from "./Slider/HalfSlider";
import Heading from "./Heading/Heading";
import Contact from "./Contact/Contact";
import logo from "../../image/logo.png";
import about from "../../image/about.jpg";
import nss_logo from "../../image/nss_logo.jpg";

export default function HomePage(props) {

  const [datas,setData]=useState([{year:2026,awards:52}]);
  useEffect(()=>{
    const response=async()=>{
      const {data}=await axios.get("https://nss.bitsathy.ac.in/api/hu/");
      setData(data);
    };
    response();
  },[])

  console.log("home", props);
  const cardsData = [
    {
      title: 2000,
      text: <span>Year Established</span>,
    },
    {
      title: 200,
      text: (
        <span>
          <span>NSS Volunteers</span>
          <br />
          <span>100 per unit</span>
        </span>
      ),
    },
    {
      title: parseInt(datas[0].awards),
      text: <span>Awards</span>,
    },
  ];
  const aboutContent = {
    heading: "About",
    contents: [
      {
        img: about,
        type: "para",
        content:
          "  National  Service  Scheme  (NSS)  of Bannari Amman Institute of Technology, Sathyamangalam, Erode is  a  permanent  youth  Programme  under  the Ministry  of  Human Resources,  Government  of  India  and  funded  by  Government  of  Tamil Nadu  and  Government  of  India. It aims at developing student’s personality through community service.",
      },
      {
        title: "OBJECTIVE(S)",
        type: "list",
        content: [
          "To understand community in which they work",
  
          "To understand themselves in relation to their community",
  
          "To identify the needs and problems of the community and involve them in problem solving process",
  
          "To develop among themselves a sense of social and civic responsibilities",
  
          "To utilize their knowledge in finding practical solutions to individual and community problems",
  
          "To develop competence required for group-living and sharing of responsibilities",
  
          "To gain skills in mobilizing community participation",
  
          "To acquire leadership qualities and democratic attitude",
  
          "To develop capacity to meet emergencies and natural disasters and",
  
          "To practice national integration and social harmony",
        ],
      },
      {
        title: "OUTCOME",
        before: "The students will",
        type: "list",
        content: [
          "Compare themselves in relation to their community and develop among themselves a sense of  social and civic responsibility",
  
          "Utilize their knowledge in finding practical solution to individual and community problems ",
  
          "Develop leadership qualities in working environment and during the time of emergency",
        ],
      },
      {
        title: "MOTTO",
        type: "para",
        content: (
          <span>
            The Motto of NSS <span className="tagline">"NOT ME BUT YOU"</span>,
            reflects the essence of democratic living and upholds the need for
            self-less service. The philosophy of the NSS is a good doctrine in
            this motto, which underlines on the belief that the welfare of an
            individual is ultimately dependent on the welfare of the society as a
            whole and therefore, the NSS volunteers shall strive for the
            well-being of the society.
          </span>
        ),
      },
      {
        title: "SYMBOL",
        img: nss_logo,
        type: "para",
        class:"main-section-symbol-img",
        content:
          " The symbol of the National Service Scheme is \"Rath\" Wheel of the Konark Sun Temple of Orissa.  These giant wheels of the Sun Temple portray the cycles of creation, preservation and release and signify the movement in life across time and space.  The design of the symbol, a simplified form of the Sun Chariot wheel primarily depicts movement. The wheel signifies the progressive cycle of life.  It stands for continuity as well as change and implies the continuous striving of NSS for social transformation and enlistment.",
      },
      {
        title: "NSS BADGE",
        type: "para",
        content:
          "The NSS logo is embossed on the badge of NSS. The eight bars in the wheel of NSS logo represent the 24 hours of a day. The red colour indicates that the volunteer is full of young blood that is lively, active, energetic and full of high spirit. The navy blue colour indicates the cosmos of which the NSS is tiny part, ready to contribute its share for the welfare of the mankind.",
      },
      {
        title: "NSS DAY",
        type: "para",
        content:
          "NSS was formally launched on 24th September 1969, the birth Centenary year of the Father of our Nation. Therefore, 24 September is celebrated every year as NSS Day with appropriate programmes and activities.     ​",
      },
    ],
  };
  














   
  return (
    <div className="home-container">
      <HomeHeader datas={datas} />
      <FullSlider api={"home"} margin1={"0px"} width1={"100%"} border1={""} />
      <MainSection
        heading={aboutContent.heading}
        contents={aboutContent.contents}
      />
      <div className="card-bg cards-container">
        {cardsData.map((card, index) => (
          <div key={index} className="cards-main">
            <p className="cards-title">{card.title}</p>
            <p className="cards-text">{card.text}</p>
          </div>
        ))}
      </div>
      <div className="service-container">
        <Heading title="our service" />
        <HalfSlider url={"service"} />
      </div>
      <Event />
      <Contact />
    </div>
  );
}
