import React from "react";
import SubHeading from "../Heading/SubHeading";

export default function FeedbackForm() {
  const reset = (e) => {
    e.preventDefault();
    e.target.reset();
  };
  return (
    <div className="feedback-form-container">
      <SubHeading title={"Feedback"} />
      <form onSubmit={reset} className="feedback-form">
        {fields.map((field,index) =>
          field.type === "input" ? (
            <input key={index} type={"text"} name={field.name} placeholder={field.name} />
          ) : (
            <textarea key={index} name={field.name} placeholder={field.name} />
          )
        )}
        <button>Submit</button>
      </form>
    </div>
  );
}

const fields = [
  { type: "input", name: "name" },
  { type: "input", name: "email" },
  { type: "input", name: "subject" },
  { type: "textarea", name: "message" },
];
