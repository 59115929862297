import React, { useState, useEffect } from "react";
import axios from "axios";

const Awards = () => {
  const [award, setAward] = useState([]);
  useEffect(() => {
    const response = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/awards/");
      // console.log(data);
      setAward(data);
    };
    response();
  }, []);
  console.log(award);

  const display = award.map((x) => {
    return (
      <div className="cardss" key={x.id}>
        <div className="im">
          <img src={x.image} alt="awards" />
        </div>
        <div className="box-container">
          <p className="box-title">{x.date}</p>
          <p className="box-content content-overflow font-sm">
            {x.description}
          </p>
        </div>
      </div>
    );
  });
  return (
    <div className="container">
      <div className="niw">
        <div className="carr ">
          {display}</div>
      </div>
    </div>
  );
};
export default Awards;
