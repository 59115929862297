import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const FullSlider = (props) => {
  const [image, setImage] = useState([]);
  const [x, setX] = useState(0);
  useEffect(() => {
    setX(0);
    const res = async () => {
      const { data } = await axios.get(
        `https://nss.bitsathy.ac.in/api/${props.api}/`
      );
      // console.log(data);
      setImage(data);
    };
    res();
  }, []);
  // console.log(border1);

  useEffect(() => {
    let timer = setTimeout(handlerRight, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [x, image]);
  // console.log(x);
  const handlerRight = () => {
    if (x < image.length - 1) {
      setX(x + 1);
    }
    if (x >= image.length - 1) {
      setX(0);
    }
  };
  const handlerLeft = () => {
    // console.log(x);

    if (x <= 0) {
      setX(image.length - 1);
    }
    if (x <= image.length - 1 && x > 0) {
      setX(x - 1);
    }
    // if(x<0){
    //     setX(image.length-2)
    // }
  };

  // console.log(props.width1);

  return (
    <div
      className="nith"
      style={{
        position: "relative",
        backgroundColor: "transparent",
        backgroundImage: "none",
        border: `${props.border1}`,
        margin: `${props.margin1}`,
      }}
    >
      <div>
        <div className="imagesss">
          {image.length > 0 ? (
            <img
              className="slider-img-main"
              src={image[x].image}
              style={{ border: "10 solid black" }}
              // style={{ width: `${props.width1}`, height: "700px",objectFit:"cover" }}
              alt="bbj"
            />
          ) : null}
        </div>

        <p onClick={handlerLeft} className="floating-btn btn-left">
          <MdArrowBackIos size={50} />
        </p>
        {image.length > 0 ? (
          <p className="image-description main-slide">{image[x].event}</p>
        ) : null}

        <p onClick={handlerRight} className="floating-btn btn-right">
          <MdArrowForwardIos size={50} />
        </p>
      </div>
    </div>
  );
};

export default FullSlider;
