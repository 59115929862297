import React from "react";
import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
// import Sapling from "./components/sapling";
import Camp from "./components/camp";
import Blood from "./components/bdc";
import Sem from "./components/seminar";
import Report from "./components/reportsmag";
import "./style/main.css";
import "./style/mobile.css";
import "./style/st.css";
import "./style/nlm.css";
// import "./style/uba.css";
import "./style/pmy.css";
import "./style/struc.css";
import "./style/uba.css";
// import Advisory from "./components/commit";
import Calender from "./components/calender";
import Volunteer from "./components/volunteer";
import Struc from "./components/structure";
import Committe from "./components/commitee";
import Awards from "./components/awards";
import Asset from "./components/assest";
import Certificate from "./components/certificate";
import Header from "./components/Header/Header";
import "./style/header.css";
import "./style/home.css";
import HomePage from "./components/Home/HomePage";
import Footer from "./components/Footer/Footer";
import Pmy from "./components/pmy";
import UBa from "./components/uba";
import Nlm from "./components/nlm";
import Other from "./components/Other";
import EventManage from "./components/Eventmanage";

const App = () => {
  console.log = console.warn = console.error = () => {};
  // let url=window.location.pathname;

  // console.log(url,"buj")

  // const res=routList
  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          {routeList.map((route) => (
            // <div key={route.}>
            <Route
              key={route.element}
              path={route.path}
              element={route.element}
              exact={route.exact ? route.exact : ""}
            />
          ))}
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};
export default App;

const routeList = [
  {
    path: "/",
    element: <HomePage />,
    exact: "/",
  },

  {
    path: "/activity",
    element: <Calender />,
  },
  {
    path: "/camp",
    element: <Camp />,
  },
  {
    path: "/blood",
    element: <Blood />,
  },
  {
    path: "/seminar",
    element: <Sem />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/volunteer",
    element: <Volunteer />,
  },
  {
    path: "/committe",
    element: <Committe />,
  },
  {
    path: "/awards",
    element: <Awards />,
  },
  {
    path: "/asset",
    element: <Asset />,
  },

  {
    path: "/volunteerlist",
    element: <Certificate />,
  },
  {
    path: "/pradhan-mantri-yojana",
    element: <Pmy />,
  },
  {
    path: "/uba",
    element: <UBa />,
  },
  {
    path: "/events",
    element: <EventManage />,
  },
  { path: "/event/:year", element: <Other /> },
  {
    path: "/structure",
    element: <Struc />,
  },
  {
    path: "/nlm",
    element: <Nlm />,
  },
  {
  path:"*",
  element:<HomePage/>}
];
