import axios from 'axios';
import React,{useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { ReactYouTubeLite } from 'react-youtube-lite';


const Other=(props)=>{
    let location=useLocation();
    let check=window.location.pathname;
    const [datas,setData]=useState([]);


    useEffect(()=>{
        const Response=async()=>{
            const {data}=await axios.get("https://nss.bitsathy.ac.in/api/others/");
            // console.log(data);
            setData(data);
        };
        Response();
    },[])
    console.log(datas)

    let even=check?check.split("/event/"):null;

    console.log(even[1])
    
    const arr=datas && even[1] ?datas.filter((x)=>{
        console.log(x.year,"nub",even[1])
         return (x.year==even[1])}):"ni"
    console.log(arr,"mi")
    const showit = arr?.map((x, index) => {
        let ar = x?.video?.split("watch?v=");
        let a = ar?.[1];
        return (
          <div className="pmy-main" key={index}>
            <div className="pmy-main_body">
              <div className="pmy_main_header">
              <h1 className="pmy-main_head">{x.event}</h1>

              <p className="pmy-main_date">{x.date}</p>

              </div>
              <hr className="pmy-main_hr"></hr>
              <p className="pmy-main_description">{x.description}</p>

              <div className="pmy-main_img">
                {x.image1 ? <img src={x.image1} /> : null}
                {x.image2 ? <img src={x.image2} /> : null}
                {x.image3 ? <img src={x.image3} /> : null}
                {x.image4 ? <img src={x.image4} /> : null}
              </div>
                {x.video ? (
                            <div className="pmy-main_video">
    
                  {/* <iframe
                    src={`https://www.youtube.com/embed/${a}`}
                  ></iframe> */}
 <div className="iframe-uba">
              <ReactYouTubeLite   url={x.video} />
              </div>                  </div>
                ) : null}
              </div>
            </div>
          // </div>
        );
      });
    
      return (
        <div className="container">
          <div className="pmy-main-container">
            {showit}
            {/* {loading ? "loading..." : showit} */}
          </div>
        </div>
      );
    };
    
    export default Other;