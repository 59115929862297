import React, { useState, useEffect } from "react";
const Certificate = () => {
  return (
    <div className="container">
      <div className="center">
        <div className="cert c box-container">
          <p className="box-title" style={{fontSize:'1.4rem'}}>
          TO VIEW THE DETAILS OF THE NSS-BIT VOLUNTEERS(YEARWISE) :
            <a
              href="https://docs.google.com/spreadsheets/d/1MM_-SoZFfUKYE4nZ2DYCkyZMV34AuTFEDsAGfgAZsL0/edit?usp=drivesdk"
              target="_blank"
            >
              CLICK HERE
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Certificate;
