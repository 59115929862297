import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from '../image/logo.png'


const EventManage = () => {
  const [Nlm, setNlm] = useState([]);
  useEffect(() => {
    const Response = async () => {
      const { data } = await axios.get(
        "https://nss.bitsathy.ac.in/api/othereventyears/"
      );
      setNlm(data);
    };
    Response();
  }, []);
  // Nlm.reverse();
  console.log(Nlm);
  const display = Nlm.map((x, index) => {
    return (
      <div key={index} className="horizontal">
        <div className="c " style={{ borderRadius: "10px" }} key={x.id}>
          <div className="  event-title">
            <Link
              to={`/event/${x}`}
              // state={{x:'x'}}
              target="_blank"
            >
              <div className="event-content">
                <img
                  src={logo}
                  alt="logo"
                />
                <p>NSS EVENTS: {x}-{x+1}</p>
              </div>
              {/* </div> */}
            </Link>
          </div>
        </div>
        <hr />
      </div>
      // {/* </div> */}
    );
  });
  return (
    <div className="container">
      <div className="event-card">{display}</div>
    </div>
  );
};
export default EventManage;
