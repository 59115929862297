import axios from "axios";
import React, { useState, useEffect } from "react";
import { ReactYouTubeLite } from 'react-youtube-lite';


const UBa = () => {
  const [Uba, setUba] = useState([]);
  useEffect(() => {
    const Response = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/uba/");
      setUba(data);
    };
    Response();
  }, []);
  // Uba.reverse();
  console.log(Uba);
  const show = Uba.map((x, index) => {
    let ar = x?.video?.split("watch?v=");
    let a = ar?.[1];
    return (
      <div className="pmy-main" key={index}>
        <div className="pmy-main_body">
          <h3 className="pmy-main_head">{x.event}</h3>
          <h4 className="pmy-main_date">{x.date} </h4>
          <hr className="pmy-main_hr"></hr>
          <p className="pmy-main_dsc uba_description">{x.description}</p>
          <div className="pmy-main_img">
            {x.image1 ? <img src={x.image1} /> : null}
            {x.image2 ? <img src={x.image2} /> : null}
            {x.image3 ? <img src={x.image3} /> : null}
            {x.image4 ? <img src={x.image4} /> : null}
          </div>
          <div className="pmy-main_video">
            {x.video ? (
              <div className="iframe-uba">
              <ReactYouTubeLite   url={x.video} />
              </div>

              // <iframe src={`https://www.youtube.com/embed/${a}`}></iframe>
            ) : null}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="pmy-main-container">{show}</div>
    </div>
  );
};

export default UBa;
