import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Pmy = () => {
  const [Pmy, setPmy] = useState([]);
  useEffect(() => {
    const Response = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/pmy/");
      setPmy(data);
    };
    Response();
  }, []);
  // Pmy.reverse();
  console.log(Pmy);
  const show = Pmy.map((x, index) => {
    return (
      <div className="pmy" key={index}>
        <div className="pmy_dig">
          <img src={x.image} className="pmy_img" alt={x.title} />
          <div className="pmy_text">
            <h1 className="pmy_head">{x.title}</h1>
            <p className="pmy_descr">{x.Objective}</p>
            <div className="pmy_link">
              
                <button className="pmy_button"> 
                <Link to={x.link} target="_blank">
                  Read More</Link>
                  </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="pmy_main">{show}</div>
    </div>
  );
};

export default Pmy;
