import axios from "axios";
import React, { useState, useEffect } from "react";
// import logo from "../image/logo.png";
// import  './logo.png';
import logo from '../image/nss_logo.jpg'

const Calender = () => {
  const [activity, setActivity] = useState([]);
  useEffect(() => {
    const Response = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/activity/");
      setActivity(data);
    };
    Response();
  }, []);
  const cal = activity.map((x, index) => {
    return (
      <div classname="activity" key={index}>
        <a iframe="true" href={x.pdf1} target="_blank">
          <img className="image-logos"
            src={logo}
            alt="mit"
          />
        </a>
        <p className="report-activity-text">{x.year}</p>
              </div>
    );
  });
  console.log(activity);
  return (
    <div className="container">
      <div className="contain">
        <h1>ACTIVITY CALENDER</h1>
        <div className="c card6">{cal}</div>
      </div>
    </div>
    // </div>
  );
};
export default Calender;
