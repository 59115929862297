import React, { useState, useEffect } from "react";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineMenu, AiFillYoutube, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../image/logo.png";
// import { Link } from "react-router-dom";
export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [displayBtn, setDisplayBtn] = useState();
  const [displayMenu, setDisplayMenu] = useState(true);

  const windowSizeChecker = () => window.innerWidth > 780;
  const windowSwitcher = () => {
    if (windowSizeChecker()) {
      setDisplayBtn(true);
      setDisplayMenu(true);
      setToggle(false);
      console.log(window.innerWidth);
    } else {
      setDisplayBtn(false);
      setToggle(true);
      setDisplayMenu(false);
    }
  };

  window.addEventListener("resize", windowSwitcher);

  useEffect(() => {
    windowSwitcher();
  }, []);

  const ToggleBtn = () => {
    setToggle((val) => setToggle(!val));
  };

  const ToggleMenu = () => {
    setDisplayMenu((display) => setDisplayMenu(!display));
    // alert(displayMenu);
  };

  const Togglefun = () => {
    if (!windowSizeChecker()) setDisplayMenu(false);
    else setDisplayMenu(true);
  };
  return (
    <div className="header-container">
      <div className="header">
        <div className="logo">
          <img
            src={logo}
            style={{ width: "auto", height: "50px" }}
            className="logo-img"
            alt="nss logo"
          />
          <span>NSS BIT</span>
        </div>
        {displayMenu && (
          <div className="header-center">
            <div className="menu">
              <ul>
                {menuItemsList.slice(0, 4).map((menu) => (
                  <li key={menu.name}>
                    <Link to={`${menu.path}`} onClick={Togglefun}>
                      {menu.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {displayBtn && (
              <button onMouseEnter={() => setToggle(true)} onClick={ToggleBtn}>
                More +
              </button>
            )}
            <ul onMouseLeave={() => setToggle(false)} className="sub-menu">
              {toggle &&
                menuItemsList.slice(4).map((menu) => (
                  <li key={menu.name}>
                    
                    {menu.name=="blogs"?<a href={`${menu.path}`} target="_blank" >{menu.name}</a>:
                                        menu.name=="contact"?
                                        <div>

                                        <a href={`${window.location.origin}${menu.path}`}>{menu.name}</a>
                                        </div>:


                    <Link to={`${menu.path}`} onClick={Togglefun}>
                      {menu.name}
                    </Link>
}
                  </li>
                ))}
            </ul>
          </div>
        )}
        <span className="floating-menu-btn" onClick={ToggleMenu}>
          {displayMenu ? <AiOutlineClose /> : <AiOutlineMenu />}
        </span>
        <div className="header-right">
          {links.map((link) => (
            <span key={link.url}>
              {
                <a href={link.url} target="_blank" className="link">
                  {link.icon}
                </a>
              }
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

const links = [
  {
    url: "https://twitter.com/NssBit",
    icon: <BsTwitter />,
  },
  {
    url:
      "https://www.youtube.com/channel/UCTLYx0iNlcJjuTKRZzUsW8A?view_as=subscriber",
    icon: <AiFillYoutube />,
  },
];
const menuItemsList = [
  {
    path: "/",
    name: "home",
  },
  {
    path: "/structure",
    name: "NSS structure",
  },
  {
    path: "/committe",
    name: "advisory committee",
  },
  {
    path: "/pradhan-mantri-yojana",
    name: "pradhan mantri yojana",
  },
  {
    path: "/awards",
    name: "awards & achievements",
  },
  {
    path: "/volunteerlist",
    name: "volunteer",
  },
  {
    path: "/report",
    name: "annual reports & magazine",
  },
  {
    path: "/activity",
    name: "activity calendar",
  },
  {
    path: "/asset",
    name: "assets created",
  },
  {
    path: "/volunteer",
    name: "volunteers talent",
  },

  {
    path: "/events",
    name: "Yearly Events",
  },
  {
    path: "/blood",
    name: "blood donation camp",
  },
  {
    path: "/seminar",
    name: "seminars/conference",
  },
  {
    path: "/camp",
    name: "camp organized",
  },
  {
    path: "https://nssbit.blogspot.com/",
    name: "blogs",
  },
    {path:"#contact",
name:"contact"}
];
