import React from "react";

export default function Footer() {
  return (
    <div className="footer-container">
      <p className="footer">
        &copy; developed by NSS. Proudly Associated with Bannari Amman Institute
        Of Technology
      </p>
    </div>
  );
}
