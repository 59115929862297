import React, { useEffect, useState } from "react";
import SubHeading from "../Heading/SubHeading";

export default function FacultyList() {
  const [facultyList, setFacultyList] = useState([]);
  useEffect(() => {
    const getFacultyList = async () => {
      const data = await fetch("https://nss.bitsathy.ac.in/api/po/");
      const list = await data.json();
      console.log(list);
      setFacultyList(list);
    };

    getFacultyList();
  }, []);
  return (
    <div className="faculty-list-container">
      <SubHeading title={"PROGRAMME OFFICERS"} />
      <div className="faculty-grid-box">
        {facultyList.length &&
          facultyList.map((list, index) => (
            <div key={index} className="faculty-grid-item">
              <img src={list.photo} alt={list.name} />
              <div className="faculty-info">
                <span className="faculty-name">{list.name}</span>
                <span className="faculty-desgination">{list.position}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

// const facultyList = [
//   {
//     img:
//       "https://static.wixstatic.com/media/d32015_5647916c5c4e418697858b81302d606b~mv2.jpg/v1/fill/w_148,h_160,al_c,q_80,usm_0.66_1.00_0.01/download%20(1)_jfif.webp",
//     name: "Dr V N VIJAYAKUMAR",
//     desgination: "NSS Programme Officer for unit I",
//   },
//   {
//     img:
//       "https://static.wixstatic.com/media/d32015_8f64882449ea428fb403741cd3b5f917~mv2_d_1341_1457_s_2.jpg/v1/fill/w_148,h_160,al_c,q_80,usm_0.66_1.00_0.01/1_R_Praveena_JPG.webp",
//     name: "Dr R PRAVEENA",
//     desgination: "NSS Programme Officer for unit II",
//   },
//   {
//     img:
//       "https://static.wixstatic.com/media/d32015_c7981251e23c4002bb30346b1414e733~mv2.jpg/v1/fill/w_148,h_160,al_c,q_80,usm_0.66_1.00_0.01/CH1578.webp",
//     name: "Dr C Kavitha",
//     desgination: "Assistant NSS Programme Officer for unit I",
//   },
//   {
//     img:
//       "https://static.wixstatic.com/media/d32015_a174d49d35f44ce88b89fc062e94b31b~mv2.jpg/v1/fill/w_148,h_160,al_c,q_80,usm_0.66_1.00_0.01/Dr_A_Mahudeswaran.webp",
//     name: "Dr A Mahudeswaran",
//     desgination: "Assistant NSS Programme Officer for unit II",
//   },
// ];
