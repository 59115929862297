import axios from "axios";
import React, { useState, useEffect } from "react";
import logo from '../image/nss_logo.jpg'
const Report = () => {
  const [report, setReport] = useState([]);

  useEffect(() => {
    const Res = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/report/");
      console.log(data);
      setReport(data);
    };
    Res();
  }, []);
  // report.reverse();
  // console.log(report)
  const rep = report.filter((x) => {
    return x.type === "Report";
  });
  // console.log('hbu',rep)

  const mag = report.filter((x) => {
    return x.type === "Magazine";
  });
  const dis = rep.map((x, index) => {
    return (
      <div  className="flex-items" key={index}>
        <a
          iframe="true"
          id="pdf-js-viewer"
          target="_blank"
          href={x.file + "#toolbar=0"}
        >
          <img
 className="image-logos"            src={logo}
            alt="logo"
          />
        </a>
        <p className="report-activity-text">{x.year}</p>
              </div>
    );
  });

  const maa = mag.map((x, index) => {
    return (
      <div  className="flex-items" key={index}>
        <a
          iframe="true"
          id="pdf-js-viewer"
          target="_blank"
          href={x.file + "#toolbar=0"}
        >
          <img className="image-logos"
            src={logo}
            alt="logo"
          />
        </a>
        <p className="report-activity-text">{x.year}</p>
      </div>
    );
  });
  return (
    <div className="container">
      <div className="contain">
        <div className="c card7">
          <h1>REPORTS</h1>
          <div className="displa">{dis}</div>
          <hr />
          <h1>MAGAZINE</h1>
          <div className="displa">{maa}</div>
        </div>
      </div>{" "}
    </div>
  );
};
export default Report;
