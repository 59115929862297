import axios from "axios";
import React, { useState, useEffect } from "react";
import { ReactYouTubeLite } from 'react-youtube-lite';


const Nlm = () => {
  const [Nlm, setNlm] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const Response = async () => {
      // lading true
      setLoading(true);
      axios
        .get("https://nss.bitsathy.ac.in/api/nlm/")
        .then((result) => {
          setNlm(result.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   loading false
          setLoading(false);
        });
    };
    Response();
  }, []);
  Nlm?.reverse();
  console.log(Nlm);
  const showit = Nlm?.map((x, index) => {
    let ar = x?.video?.split("watch?v=");
    let a = ar?.[1];
    return (
      <div className="pmy-main" key={index}>
        <div className="pmy-main_body">
          <div className="pmy_main_header">
          <h1 className="pmy-main_head">{x.event}</h1>
          <p className="pmy-main_date">{x.date}</p>
          <p className="pmy-main_date">{x.place}</p>
          </div>
          <hr className="pmy-main_hr"></hr>
          <div className="pmy-main_img">
            {x.image1 ? <img src={x.image1} /> : null}
            {x.image2 ? <img src={x.image2} /> : null}
            {x.image3 ? <img src={x.image3} /> : null}
            {x.image4 ? <img src={x.image4} /> : null}
          </div>
            {x.video ? (
                        <div className="pmy-main_video">

              {/* <iframe
                src={`https://www.youtube.com/embed/${a}`}
              ></iframe> */}
               <div className="iframe-uba">
              <ReactYouTubeLite   url={x.video} />
              </div>
              </div>
            ) : null}
          </div>
        </div>
      // </div>
    );
  });

  return (
    <div className="container">
      <div className="pmy-main-container">
        {loading ? "loading..." : showit}
        {/* {loading ? "loading..." : showit} */}
      </div>
    </div>
  );
};

export default Nlm;
// import React from 'react'

// export default function Nlm() {
//     return (
//         <div>

//         </div>
//     )
// }
