import React, { useState, useEffect } from "react";

import axios from "axios";

const Asset = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const asse = async () => {
      const { data } = await axios.get("https://nss.bitsathy.ac.in/api/assets/");
      console.log(data);
      setdata(data);
    };
    asse();
  }, []);
  if (data.length !== 0) {
    console.log(data[0].file);
  }
  return (
    <div className="container">
      <div className="center">
        {/* {data[0]?<iframe frame="true" src={data[0].file}></iframe>:null} */}
        <div className="cert c">
          <h3>
            TO VIEW THE DETAILED DESCRIPTION OF THE ASSET:
            {data[0] ? (
              <a
                iframe="true"
                href={data[0].file + "#toolbar=0"}
                target="_blank"
              >
                CLICK HERE 
              </a>
            ) : null}
          </h3>
        </div>
      </div>
    </div>
  );
};
export default Asset;
